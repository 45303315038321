import React, { useContext, useMemo, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'

import { ModalContentHeader } from '../components/ModalHeader'
import ModalLayout from '../components/Layouts/ModalLayout'
import PrismicPicture, {
  PrismicBreakpointPicture,
} from '../components/PrismicPicture'

import { useTranslation } from 'react-i18next'
import { internationalRoutes, routeNames } from '../utils/helpers/routes'
import { getPrismicDocument } from '../utils/helpers/transformers'
import { animateEntry, setEntry } from '../utils/page-animations/project'
import { capitalizeFirstLetter } from '../utils/helpers/utils'
import {
  animateModalHeaderContent,
  setModalHeaderContent,
} from '../utils/page-animations/common'
import { HistoryContext } from '../utils/hooks/History'
import useAnimationState from '../utils/hooks/useAnimationState'

import ScrollTrigger from 'gsap/ScrollTrigger'

const Project = (props) => {
  const { data, pageContext } = props
  const { routeName, language } = pageContext

  //INTN
  const { t } = useTranslation(['projects'])
  const localizedRouteProjects =
    internationalRoutes[routeNames.projects].langs[language]

  // ANIMATION
  const animationState = useAnimationState({ routeName })

  // CONTEXTS
  const { isLandingPage } = useContext(HistoryContext)

  // REF
  const $hasRunAnimation = useRef(false)

  //DATA
  const needsLandingContent = useMemo(
    () => animationState.isEntryPage && isLandingPage,
    [animationState.isEntryPage, isLandingPage]
  )

  const project = useMemo(() => data?.prismicProject?.data, [data])
  const client = useMemo(
    () => getPrismicDocument(project, 'client')?.data,
    [project]
  )
  const imgsDesktop = useMemo(
    () => project && project.images?.filter(({ image }) => !!image.url),
    [project]
  )
  const imgsTablet = useMemo(
    () => project && project.images_tablet?.filter(({ image }) => !!image.url),
    [project]
  )
  const imgsMobile = useMemo(
    () =>
      project &&
      project.images_mobile?.filter(({ image_mobile }) => !!image_mobile.url),
    [project]
  )

  const clientName = client?.name?.text
  const projectName = project.name.text
  const landingLink = {
    url: localizedRouteProjects,
    label: t('projects:landing_link:label'),
  }

  // SEO
  const seoData = useMemo(() => {
    return {
      title: project.seo_title
        ? project.seo_title
        : `Source — ${clientName || ''} — ${projectName}`,
      description: project.seo_description
        ? project.seo_description
        : `${project.tags?.raw?.map((t) => t?.text).join(', ')} - ${
            project.description.text
          }`,
      image: project.seo_image.url ? project.seo_image.url : null,
    }
  }, [project, projectName, clientName])

  // HOOKS
  useEffect(() => {
    if (!animationState.canStart) {
      return
    }
    ScrollTrigger.clearScrollMemory()
    setEntry()
  }, [animationState.canStart])

  useEffect(() => {
    if (
      !animationState.canStart ||
      !needsLandingContent ||
      $hasRunAnimation.current
    ) {
      return
    }
    setModalHeaderContent()
  }, [animationState.canStart, needsLandingContent])

  useEffect(() => {
    if (!animationState.canPlay) {
      return
    }

    ScrollTrigger.clearScrollMemory()
    const tls = animateEntry()

    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [animationState.canPlay])

  useEffect(() => {
    if (
      !animationState.canPlay ||
      !needsLandingContent ||
      $hasRunAnimation.current
    ) {
      return
    }

    const tl = animateModalHeaderContent('.project')
    $hasRunAnimation.current = true
    return () => tl && tl.kill()
  }, [animationState.canPlay, needsLandingContent])

  if (!project) return null

  return (
    <ModalLayout
      name={routeNames.project}
      seo={seoData}
      language={language}
      headerBgColor="#E9E9E9"
      subtitle={capitalizeFirstLetter(clientName)}
      title={capitalizeFirstLetter(projectName)}
      uri={props.uri}
      internationalRoute={props.pageContext.internationalRoute}
      landingLink={landingLink}
      isLandingPage={needsLandingContent}
    >
      <div className="container">
        {needsLandingContent && (
          <ModalContentHeader
            subtitle={capitalizeFirstLetter(clientName)}
            title={capitalizeFirstLetter(projectName)}
            classNameInfos="col col-md-11 col-md-offset-1 col-lg-offset-1 col-lg-11"
          />
        )}
        <div className="row pt-4 pt-md-6">
          <div className="col col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-12 f f-jc-space-b mb-lg-0">
            <div
              className="tpl-project__tags ft-primary-m-small"
              dangerouslySetInnerHTML={{ __html: project.tags.html }}
            />
            <span className="tpl-project__year ft-primary-m-small">
              {project.year}
            </span>
          </div>
        </div>
        <div className="row mt-6 mt-md-2">
          <div className="col col-md-offset-4 col-md-8 col-lg-offset-5 col-lg-8">
            <h1
              className="tpl-project__title page__title h2 ft-400 ft-no-select"
              aria-label={project.name.text}
            >
              {project.name.text}
            </h1>
          </div>
        </div>
        <div className="row mt-3 mt-md-4">
          <div className="col col-md-offset-4 col-md-8 col-lg-offset-5 col-lg-5 max-width-450px">
            <p className="page__desc tpl-project__desc mb-0 ft-default-m-small">
              {project.description.text}
            </p>
          </div>
        </div>
        <div className="row mt-6 mt-b-md-1">
          <div className="col col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-12">
            <div className="mosaic mosaic--gap-24 mosaic--3">
              <PrismicPicture
                type="fixed"
                image={{
                  ...project.thumbnail,
                  alt: project.thumbnail.alt || project.name.text,
                }}
                className="tpl-project__thumbnail img--full img--top-left"
                lazy
              />
              <PrismicBreakpointPicture
                className="tpl-project__cover img--full img--top-left"
                sources={{
                  all: project.cover_image_all,
                  md: project.cover_image_md,
                }}
                fallbackImg={project.cover_image}
                fallbackAlt={project.name.text}
                lazy
              />
            </div>
          </div>
        </div>
        {imgsDesktop.length > 0 && (
          <div className="tpl-project__images row f">
            <div className="col col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-12">
              {imgsDesktop.map((item, index) => {
                return (
                  item.image && (
                    <div key={index} className="tpl-project__group">
                      <PrismicBreakpointPicture
                        className="img--full"
                        sources={{ all: item.image_all, md: item.image_md }}
                        fallbackImg={item.image}
                        fallbackAlt={project.name.text}
                        key={index}
                        lazy
                      />
                    </div>
                  )
                )
              })}
            </div>
          </div>
        )}
        {imgsTablet.length > 0 && (
          <div className="tpl-project__images row f aa">
            <div className="col col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-12">
              <div className="mosaic mosaic-md-2 mosaic-lg-2 mosaic--gap-24">
                {imgsTablet.map(
                  (item, index) =>
                    item.image && (
                      <PrismicBreakpointPicture
                        sources={{ all: item.image_all }}
                        fallbackImg={item.image}
                        fallbackAlt={project.name.text}
                        key={index}
                        lazy
                      />
                    )
                )}
              </div>
            </div>
          </div>
        )}
        {imgsMobile.length > 0 && (
          <div className="tpl-project__images row f">
            <div className="col col-md-offset-1 col-md-10 col-lg-offset-1 col-lg-12">
              <div className="mosaic mosaic--3 mosaic--gap-24">
                {imgsMobile.map(
                  (item, index) =>
                    item.image_mobile && (
                      <PrismicBreakpointPicture
                        sources={{
                          all: item.image_mobile_all,
                          md: item.image_mobile_md,
                        }}
                        fallbackImg={item.image_mobile}
                        fallbackAlt={project.name.text}
                        key={index}
                        lazy
                      />
                    )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export const queryProject = graphql`
  query ($uid: String, $language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicProject(uid: { eq: $uid }, lang: { eq: $contentLang }) {
      data {
        ...prismicProjectDataFull
      }
    }
  }
`

export default Project
